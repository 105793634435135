.toolbar {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

/* Bubble Menu Styles */
.bubble-menu {
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  display: flex;
  gap: 5px;
  position: absolute; /* Position bubble menu absolutely */

  z-index: 10;
}

/* Description Editor Container Styles */
.editor-container {
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative; /* Ensure the editor container is relatively positioned */
}

/* Make the editor content fill the container */
.ProseMirror {
  flex-grow: 1;
  min-height: 100%;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5;
  border: none;
  outline: none;
}

/* Add styles for code block background and indentation */
pre {
  background-color: #f5f5f5; /* Light grey background */
  padding: 10px; /* Adds padding inside the code block */
  border-radius: 5px; /* Adds some rounding to the corners */
  overflow-x: auto; /* Ensures that long code lines scroll horizontally */
}

code {
  font-family: "Fira Code", monospace; /* Use a monospaced font for code */
  font-size: 14px;
  color: #333; /* Dark grey text */
}

.hljs {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

/* Ensure that preformatted text (code blocks) gets indentation */
pre code {
  display: block;
  white-space: pre;
  overflow-x: scroll;
  margin-left: 20px; /* Add some indentation */
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.editor-container img {
  cursor: grab;
}

.editor-container img:active {
  cursor: grabbing;
}

.ProseMirror-dropcursor {
  border-left: 2px solid black;
  pointer-events: none;
  position: absolute;
}

.mention-suggestion {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 200px;
  position: absolute;
  z-index: 9999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.mention-suggestion-item {
  padding: 5px;
  cursor: pointer;
}

.mention-suggestion-item.is-selected {
  background-color: #f0f0f0;
}
