.board-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
}

.board-item:hover {
  background: rgba(184, 181, 181, 0.1);
}

.board-item-big {
  display: flex;
  cursor: pointer;
  padding: 1px;
  color: white;
}

.board-item-big:hover {
  background: rgba(184, 181, 181, 0.1);
}

.board-hover {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.board-hover::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

.board-hover:hover {
  transform: scale(1.05);
}

.board-hover:hover::before {
  top: -50%;
  left: 100%;
  opacity: 1;
}



/* .task-item {
  display: flex;
  cursor: pointer;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
} */

.task-item {
  margin: 10px 0;
  cursor: pointer;
  border: solid;
  border-color: white;
}

.task-item:hover {
  border: solid;
  border-color: #a0ad39;
}

.scrollable-container {
  overflow-y: auto;
  flex: 1;
}

.fade-enter {
  opacity: 0;
  transition: opacity 300ms;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit-active {
  opacity: 0;
}
