.rdw-link-modal,
.rdw-image-modal {
  background-color: var(--editor-background-color) !important;
  border-color: var(--button-text-color) !important;
  box-shadow: 3px 3px 8px var(--button-text-color);
  border-radius: 10px;
}

.rdw-editor-toolbar button {
  background-color: var(--button-background-color) !important;
  color: var(--button-text-color) !important;
}

/* Additional styling for button hover state if needed */
.rdw-editor-toolbar button:hover {
  background-color: var(--button-hover-background-color) !important;
}

.rdw-dropdown-wrapper {
  color: black;
}


.description-editor a {
  color: var(--custom-link-color) !important;
  text-decoration: underline !important;
}

.mention-suggestions {
  position: absolute; /* Absolute positioning relative to the editor container */
  z-index: 1000; /* Ensure it's above other elements */
  background-color: red; /* Red background as per your request */
  color: black; /* Text color */
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 200px; /* Limit the height of the suggestions list */
  overflow-y: auto; /* Allow scrolling if the list is too long */
}



.rdw-image-alignment,
.rdw-image-imagewrapper {
  display: block;
  text-align: center;
  max-width: 100%;  /* Ensures the figure wrapper respects the container width */
  max-height: 400px; /* Limits the height of the image wrapper */
  overflow: hidden; /* Ensures content doesn't overflow */
}

.rdw-image-imagewrapper img {
  max-width: 100%;  /* Scale the image within the figure */
  height: auto;     /* Maintain aspect ratio */
  max-height: 400px; /* Prevent the image from growing beyond a certain height */
  object-fit: contain; /* Ensures the image is contained within the defined dimensions */
}
.selected-image {
  border: 2px solid blue;
}
