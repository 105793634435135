.react-mentions__suggestions {
  top: auto !important;
  bottom: 100% !important;
  margin-bottom: 5px;
}

.mentions-container {
  background-color: #ff0000;
  text-align: center;
  color: black;
  border-radius: 3px;
}

textarea:focus-visible {
  outline: solid 1px #a0ad39;
  box-shadow: none;
}

.mention {
  background-color: #a0ad39;
  border-radius: 8px;
  padding: 3px 6px;
  color: white;
  text-decoration: none;
}
